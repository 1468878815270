.dashboard
  
      
  .dashboard-cards
    display: flex
    label
      color: #9291A5//var(--secondary-text)
    >div
      &:first-child
        flex-grow: 1 
    .dash-cards-flex-cont
      display: flex
      >*
        flex-grow: 1
    
    .pie-charts-cont
      display: grid
      grid-template-columns: 1fr 1fr

  .dashboard-card
    background: white
    border-radius: 16px
    padding: 20px
    margin: 16px
    box-shadow: 0 1px 2px 0px var(--ema-very-light-blue)
    .dash-card-title
      font-size: 20px
      display: block
      font-weight: bold
    .dash-card-sec-title
      font-size: 14px
      margin: 0
    .dash-card-value-big-text
      font-size: 24px
      font-weight: bold
    .dash-card-trend
      font-size: 14px
      &.positive
        color: #04CE00
      &.negative
        color: #F44336


    &.dash-active-card 
      display: flex 
      justify-content: space-between
      align-items: center
      &:hover
        cursor: pointer
      
    &.pie-chart-card
      //display: flex
      .pie-chart-card_title
        label
          font-size: 14px
          margin: 0
        strong
          font-size: 20px
          display: block
      .pie-chart-card_body
        display: grid
        grid-template-columns: 1fr auto
        >*
          flex-grow: 1
        .pie-chart-circle
          canvas
            width: 100% !important
            height: auto !important
        .pie-chart-circle_items
          padding-left: 20px
          width: 230px
          li
            display: flex
            align-items: center
            list-style: none
            justify-content: space-between
            font-size: 13px
            &:not(:last-child)
              margin-bottom: 10px
            div
              display: flex
              align-items: center
            span
              height: 8px
              min-width: 8px
              border-radius: 50%
              margin: 4px

