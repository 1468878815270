.operator-dashboard
  display: flex
  flex-direction: row
  justify-content: space-between
  margin-top: 10px
  padding: 15px 30px

  .operator-dashboard-card
    border-radius: 8px
    background-color: #fff
    box-shadow: 0 0 8px var(--ema-very-light-blue)
    flex-basis: 18%
    padding: 20px
    &.selected
      border: solid 2px
    .operator-dashboard-label
     font-size: 16px
     color: var(--secondary-text)
    .operator-dashboard-count
     font-weight: bold
     font-size: 22px

   

