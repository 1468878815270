

.profile-dopdown
  .dropdown-menu.show
    padding: 16px
    box-shadow: 0 0 3px  var(--ema-light-gray)
    border-radius: 8px
    .dropdown-item
      margin: 16px 
      padding: 0
      font-size: 14px
      display: flex
      align-items: center
    .profile-menu-text
      font-size: 14px
      font-weight: 500
      color: var(--main-text)
      padding: 4px 0
      
    
    .profile-icon
      color:  var(--secondary-text)
      font-size: 18px
      margin-right: 12px
      

  .dropdown-toggler
    background-color: none
    border: none
    background: #bd900a
    padding: 6px
    border-radius: 50%
    margin-left: 8px
    border: 0px
    width: 36px
    height: 36px
    display: flex
    align-items: center
    justify-content: center